/**
 * @file 
 * @summary Authentication methods / User Handling
 * @author Arthur Moore <ArthurMO@mohela.com>
 * @copyright MOHELA 2024
 */

import { Logger, User, UserManagerSettings, CheckSessionIFrame, UserManager, JwtClaims, IdTokenClaims } from "oidc-client-ts";

const OidcSettingsElementId = "oidc_settings";
const UserDataElementId = "user_data";

class ApplicationUserManager extends UserManager {
  public async getClaimsFromJwt(responseText: string): Promise<JwtClaims> {
    const inner: (responseText: string) => Promise<JwtClaims> = (this as any)._client._validator._userInfoService._getClaimsFromJwt;
    return inner(responseText);
  }
}

/**
 * @summary Log the user out.
 * @todo Should use the oidc.UserManager and implement the APIs needed.
 */
export function logout(): void {
  Logger.debug("Logging Out");
  let logout_form = document.getElementById('logoutForm') as HTMLFormElement;
  if (logout_form == undefined) {
    logout_form = window.document.createElement("form");
    logout_form.method = "post";
    logout_form.action = "/Account/LogOff";  //@todo Should not be hard-coded.
    window.document.body.appendChild(logout_form);
  }
  logout_form.submit();
}

/**
 * @summary Obtain some data from a <script type="application/json">{}</script> tag.
 * @param tag_id Id of the script tag containing the JSON data.
 * @returns A JSON object, or null if the element does not exist or cannot be parsed.
 */
export function getObjectFromJstonTag(tag_id: string): object | null {
  const settings_element = document.getElementById(tag_id) as HTMLScriptElement;
  if (settings_element == undefined) {
    return null;
  }
  try {
    const data = JSON.parse(settings_element.text);
    if (typeof data !== 'object') {
      return null;
    }
    return data;
  } catch (e) {
    Logger.warn(`Unable to parse {tag_id} as JSON`)
  }
  return null;
}

/**
 * @summary UserManagerSettings are injected into the page when the user has logged in via OIDC.
 * @see FsaPortal/Security/OpenIdConnectDataHelper.cs for more information.
 * @returns UserManagerSettings if User is logged in via OIDC. Otherwise null.
 */
export function getOidcUserManagerSettingsFromPage(): UserManagerSettings | null {
  return getObjectFromJstonTag(OidcSettingsElementId) as UserManagerSettings | null;
}

/**
 * @summary Some User information is injected into the page when a user is logged in.
 * @description Includes some information even if the user is not logged in via OIDC.
 * @see FsaPortal/Security/OpenIdConnectDataHelper.cs for more information
 * @returns Partial<User> if User is logged in. Otherwise null.
 */
export async function getUserFromPage(): Promise<User | null> {
  let user_data = getObjectFromJstonTag(UserDataElementId) as User & { userState?: unknown } | null;
  if (!user_data) {
    return null;
  }
  if (!user_data.userState) {
    user_data.userState = user_data.state;  // Needed to make user copyable.
  }
  // Make sure that even an empty object is valid.
  user_data.access_token = user_data.access_token ? user_data.access_token : "";
  user_data.token_type = user_data.token_type ? user_data.token_type : "";

  let user = new User(user_data);
  const manager = getUserManager();
  try {
    user.profile = await(manager.getClaimsFromJwt(user.id_token!)) as IdTokenClaims;
  } catch (e) {
    // Deliberately doing nothing.
  }
  await manager.storeUser(user as User);

  return user;
}

/**
 * @summary Use an OIDC IFrame to check if a user's session status has changed.
 * @description Logs the user out if it has.
 * @todo should really check if the user has truly logged out instead of just assuming change == logout.
 */
export async function monitorLoginStatus() {
  const logger = Logger.createStatic("FsaPortal", "monitorLoginStatus");
  const oidc_settings = getOidcUserManagerSettingsFromPage();
  if (!oidc_settings) {
    logger.debug("OIDC data not available")
    return;
  }
  let user = await getUserFromPage();
  if (!oidc_settings || !user) {
    logger.debug("User not logged in via OIDC, or data not available.")
    return;
  }

  if (!oidc_settings.metadata?.check_session_iframe) {
    logger.warn("User logged in via OIDC, but 'UserManagerSettings.metadata.check_session_iframe' not set.")
    return;
  }

  const js_frame = new CheckSessionIFrame(async () => logout(), oidc_settings.client_id, oidc_settings.metadata?.check_session_iframe!, oidc_settings.checkSessionIntervalInSeconds ?? 2, oidc_settings.stopCheckSessionOnError ?? true);
  await js_frame.load();
  js_frame.start(user.session_state ?? "");
}

declare global {
  interface Window {
    _userManager: ApplicationUserManager | undefined;
  }
}

/**
 * @summary Singleton to obtain a user manager.
 * @returns
 */
export function getUserManager(): ApplicationUserManager {
  if (window._userManager) {
    return window._userManager;
  }
  let settings = getOidcUserManagerSettingsFromPage() as UserManagerSettings;
  settings.automaticSilentRenew = false;
  settings.monitorSession = false;

  const manager = new ApplicationUserManager(settings);
  window._userManager = manager;
  Logger.debug("Registered ApplicationUserManager singleton.")
  return manager;
}
