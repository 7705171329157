/***
 * @file
 * @summary TypeScript Entry Point
 * @author Arthur Moore <ArthurMO@mohela.com>
 * @copyright MOHELA 2024
 ***/

import { createBrowserRouter } from "react-router-dom";
import { Log as LoggerConfig } from "oidc-client-ts";
import { logout, monitorLoginStatus } from "security/Authentication";

// JQuery that can also be accessed at runtime.
import jQuery from 'jquery'; // Can not be used since work arround for https://github.com/vitejs/vite/discussions/9415
const $: JQueryStatic = jQuery;
declare global {
    interface Window {
        jQuery: JQueryStatic,
        $: JQueryStatic
    }
}
(window as any).$ = jQuery;  // Make jQuery available at runtime
(window as any).jQuery = jQuery;  // Make jQuery available to jQuery-UI

// Form Validation
import "jquery-validation";
import "jquery-validation-unobtrusive";  // Validate ASP.NET Forms without having to do anything extra.

// UI Components
import "bootstrap";
// Using await to work around a bug where jQuery-UI would be imported before jQuery was had set the globals.
await import("jquery-ui/dist/jquery-ui");  // Pulling in the entire compiled file instead of individual items.

// Using the router to execute custom per page javascript
const router = createBrowserRouter([
    {
        path: "/:brand/Report/ASA",
        loader: async () => (await import("pages/Report/ASA")).default()
    },
    {
        path: "/:brand/Report/Survey",
        loader: async () => (await import("pages/Report/Survey")).default()
    },
    {
        path: "/:brand/Report/Delinquencies",
        loader: async () => (await import("pages/Report/Delinquencies")).default()
    },
]);
//(window as any).router = router;

(window as any).logout = logout;

/**
 * @summary Alternative method allowing inline JavaScript to run while accessing global resources.
 * @description Allows for inline JavaScript while still having JQuery available!
 *              Also allows for individual TypeScript functions to be accessed by the inline JavaScript.
 * @todo Some .js files use `$(document).ready` instead of `document.addEventListener('ClientLoaded', ...)`
 */
function runInlineJavaScript() {
  // Specific initialization functions
  const initializationFunctions: any = {
    
  };
  (window as any).PageInit = initializationFunctions;

  const loadedEvent = new Event("ClientLoaded", { bubbles: true, cancelable: false });
  //JQuery recommended version of `$(document).ready`
  $(function () {
    //alert("TypeScript JQuery Testing in progress");
    monitorLoginStatus();
    document.dispatchEvent(loadedEvent);
  });
}

LoggerConfig.setLevel(LoggerConfig.DEBUG);
LoggerConfig.setLogger(window.console);

runInlineJavaScript();
